
.guide-visual {
    aspect-ratio: 4/3;
    position: relative;
    &__inner {
        height: 100%;
        display: flex;
        align-items: center;
    }

    h1 {
        color: #624848;
    }

    .scrolldown {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (min-width: 1024px) {
    .guide-visual {
        aspect-ratio: 1920 / 800;
    }
}
