
.scrolldown {
    color: #333;
    &--white {
        color: #fff;
    }
    &__mouse {
        width: 24px;
        height: 40px;
        border: 2px solid currentColor;
        border-radius: 999px;
        margin-bottom: 8px;
        color: inherit;
        &::after {
            content: "";
            position: absolute;
            top: 10px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            color: inherit;
            background-color: currentColor;
            animation: ScrolldownMouse 2s infinite;
        }
    }
    &::after {
        content: "";
        display: block;
        width: 2px;
        height: 20px;
        background-color: currentColor;
        margin: 0 auto;
    }
}

@media (min-width: 1024px) {
    .scrolldown {
        &__mouse {
            margin-bottom: 12px;
        }
        &::after {
            height: 60px;
        }
    }
}

@keyframes ScrolldownMouse {
    0% {
        transform: translateX(-50%) translateY(0);
    }

    80% {
        transform: translateX(-50%) translateY(10px);
    }
}
