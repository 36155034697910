
.section {
    position: relative;
}
.day-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .v-card {
        &__title,
        &__text,
        &__image {
            padding-left: 12px;
            padding-right: 12px;
        }
        &__image {
            margin-bottom: 12px;
            .v-image {
                border-radius: 12px;
            }
        }
    }
}
.business-support {
    background-position-x: 80% !important;
}
.process-list {
    > [class*="col"] {
        .process-card {
            padding: 40px;
            height: 100%;
            border-radius: 16px;
            max-width: 400px;
            margin: 0 auto;
        }
        &:not(:last-child) {
            .process-card {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: 50%;
                    bottom: -28px;
                    transform: translateX(50%) rotate(90deg);
                    width: 24px;
                    height: 24px;
                    background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                }
            }
        }
    }
}
.business-card {
    border-radius: 16px !important;
    margin: 0 auto;
    height: 100%;
    .v-card {
        &__text {
            word-break: keep-all;
        }
    }
}
@media (min-width: 768px) {
    .day-card {
        height: 100%;
        .v-card {
            &__title,
            &__text,
            &__image {
                padding-left: 24px;
                padding-right: 24px;
            }
            &__title {
                padding-top: 40px;
                padding-bottom: 16px;
            }
            &__text {
                padding-bottom: 32px;
            }
            &__image {
                margin-bottom: 40px;
                .v-image {
                    border-radius: 16px;
                }
            }
        }
    }
    .process-list {
        > [class*="col"] {
            &:not(:last-child) {
                .process-card {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        right: -32px;
                        bottom: 50%;
                        transform: translatey(50%);
                        width: 24px;
                        height: 24px;
                        background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                    }
                }
            }
        }
    }
    .business-card {
        .v-card {
            &__title,
            &__text {
                padding-left: 28px;
                padding-right: 28px;
            }
            &__title {
                padding-top: 40px;
                padding-bottom: 24px;
            }
            &__text {
                padding-bottom: 40px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .information-card {
        height: 100%;
        .v-card {
            &__title,
            &__text,
            &__actions {
                padding-left: 60px;
                padding-right: 60px;
            }
            &__title {
                padding-top: 60px;
            }
            &__actions {
                padding-bottom: 60px;
            }
        }
    }
}
